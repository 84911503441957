:root {
  --image-width: 200px;
  --progress: 2%;
}

.title:not(:last-child), .subtitle:not(:last-child) {
  margin-bottom: 1.5rem;
}

.hero-video {
  position: absolute;
  inset: 0;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
  min-width: 300px;
  font-size: 16px;
  overflow: hidden scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: Helvetica, Arial, sans-serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #363636;
}

code {
  color: #da1039;
  background-color: #f5f5f5;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: 1.5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #363636;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #4a4a4a;
  white-space: pre;
  word-wrap: normal;
  background-color: #f5f5f5;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: #363636;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.container {
  flex-grow: 1;
  width: auto;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (width >= 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (width <= 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width <= 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (width >= 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width >= 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.title, .subtitle {
  word-break: break-word;
}

.title em, .title span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title sub, .subtitle sub, .title sup, .subtitle sup {
  font-size: .75em;
}

.title .tag, .subtitle .tag {
  vertical-align: middle;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

.title strong {
  color: inherit;
  font-weight: inherit;
}

.title:not(.is-spaced) + .subtitle {
  margin-top: -1.25rem;
}

.title.is-1 {
  font-size: 3rem;
}

.title.is-2 {
  font-size: 2.5rem;
}

.title.is-3 {
  font-size: 2rem;
}

.title.is-4 {
  font-size: 1.5rem;
}

.title.is-5 {
  font-size: 1.25rem;
}

.title.is-6 {
  font-size: 1rem;
}

.title.is-7 {
  font-size: .75rem;
}

.subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
  font-weight: 600;
}

.subtitle:not(.is-spaced) + .title {
  margin-top: -1.25rem;
}

.subtitle.is-1 {
  font-size: 3rem;
}

.subtitle.is-2 {
  font-size: 2.5rem;
}

.subtitle.is-3 {
  font-size: 2rem;
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle.is-6 {
  font-size: 1rem;
}

.subtitle.is-7 {
  font-size: .75rem;
}

.column {
  flex: 1 1 0;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (width <= 768px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (width >= 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (width <= 1023px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (width >= 1024px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (width >= 1216px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (width >= 1408px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (width >= 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title {
  color: #0a0a0a;
}

.hero.is-white .subtitle {
  color: #0a0a0ae6;
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #0a0a0a;
}

@media screen and (width <= 1023px) {
  .hero.is-white .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: #0a0a0ab3;
}

.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white .navbar-link:hover, .hero.is-white .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.hero.is-white .tabs a {
  color: #0a0a0a;
  opacity: .9;
}

.hero.is-white .tabs a:hover {
  opacity: 1;
}

.hero.is-white .tabs li.is-active a {
  opacity: 1;
  color: #fff !important;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #0a0a0a;
}

.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
}

@media screen and (width <= 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
  }
}

.hero.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-black strong {
  color: inherit;
}

.hero.is-black .title {
  color: #fff;
}

.hero.is-black .subtitle {
  color: #ffffffe6;
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-black .navbar-menu {
    background-color: #0a0a0a;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: #ffffffb3;
}

.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black .navbar-link:hover, .hero.is-black .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.hero.is-black .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-black .tabs a:hover {
  opacity: 1;
}

.hero.is-black .tabs li.is-active a {
  opacity: 1;
  color: #0a0a0a !important;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fff;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
}

@media screen and (width <= 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
  }
}

.hero.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-light strong {
  color: inherit;
}

.hero.is-light .title {
  color: #000000b3;
}

.hero.is-light .subtitle {
  color: #000000e6;
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-light .navbar-menu {
    background-color: #f5f5f5;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: #000000b3;
}

.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light .navbar-link:hover, .hero.is-light .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.hero.is-light .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-light .tabs a:hover {
  opacity: 1;
}

.hero.is-light .tabs li.is-active a {
  opacity: 1;
  color: #f5f5f5 !important;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
}

@media screen and (width <= 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
  }
}

.hero.is-dark {
  color: #fff;
  background-color: #363636;
}

.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-dark strong {
  color: inherit;
}

.hero.is-dark .title {
  color: #fff;
}

.hero.is-dark .subtitle {
  color: #ffffffe6;
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: #363636;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: #ffffffb3;
}

.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.hero.is-dark .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-dark .tabs a:hover {
  opacity: 1;
}

.hero.is-dark .tabs li.is-active a {
  opacity: 1;
  color: #363636 !important;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #fff;
}

.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #363636;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
}

@media screen and (width <= 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
  }
}

.hero.is-primary {
  color: #fff;
  background-color: #b3000c;
}

.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-primary strong {
  color: inherit;
}

.hero.is-primary .title {
  color: #fff;
}

.hero.is-primary .subtitle {
  color: #ffffffe6;
}

.hero.is-primary .subtitle a:not(.button), .hero.is-primary .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: #b3000c;
  }
}

.hero.is-primary .navbar-item, .hero.is-primary .navbar-link {
  color: #ffffffb3;
}

.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary .navbar-link.is-active {
  color: #fff;
  background-color: #9a000a;
}

.hero.is-primary .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-primary .tabs a:hover {
  opacity: 1;
}

.hero.is-primary .tabs li.is-active a {
  opacity: 1;
  color: #b3000c !important;
}

.hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fff;
}

.hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #b3000c;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #80001e 0%, #b3000c 71%, #cd1400 100%);
}

@media screen and (width <= 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #80001e 0%, #b3000c 71%, #cd1400 100%);
  }
}

.hero.is-link {
  color: #000000b3;
  background-color: #fff;
}

.hero.is-link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-link strong {
  color: inherit;
}

.hero.is-link .title {
  color: #000000b3;
}

.hero.is-link .subtitle {
  color: #000000e6;
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-link .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: #000000b3;
}

.hero.is-link a.navbar-item:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link .navbar-link:hover, .hero.is-link .navbar-link.is-active {
  color: #000000b3;
  background-color: #f2f2f2;
}

.hero.is-link .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-link .tabs a:hover {
  opacity: 1;
}

.hero.is-link .tabs li.is-active a {
  opacity: 1;
  color: #fff !important;
}

.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #fff;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
}

@media screen and (width <= 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
  }
}

.hero.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-info strong {
  color: inherit;
}

.hero.is-info .title {
  color: #fff;
}

.hero.is-info .subtitle {
  color: #ffffffe6;
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-info .navbar-menu {
    background-color: #3e8ed0;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: #ffffffb3;
}

.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info .navbar-link:hover, .hero.is-info .navbar-link.is-active {
  color: #fff;
  background-color: #3082c5;
}

.hero.is-info .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-info .tabs a:hover {
  opacity: 1;
}

.hero.is-info .tabs li.is-active a {
  opacity: 1;
  color: #3e8ed0 !important;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fff;
}

.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #3e8ed0;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
}

@media screen and (width <= 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
  }
}

.hero.is-success {
  color: #fff;
  background-color: #48c78e;
}

.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-success strong {
  color: inherit;
}

.hero.is-success .title {
  color: #fff;
}

.hero.is-success .subtitle {
  color: #ffffffe6;
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-success .navbar-menu {
    background-color: #48c78e;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: #ffffffb3;
}

.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success .navbar-link:hover, .hero.is-success .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.hero.is-success .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-success .tabs a:hover {
  opacity: 1;
}

.hero.is-success .tabs li.is-active a {
  opacity: 1;
  color: #48c78e !important;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fff;
}

.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #48c78e;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
}

@media screen and (width <= 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
  }
}

.hero.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-warning strong {
  color: inherit;
}

.hero.is-warning .title {
  color: #000000b3;
}

.hero.is-warning .subtitle {
  color: #000000e6;
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: #ffe08a;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: #000000b3;
}

.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffd970;
}

.hero.is-warning .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-warning .tabs a:hover {
  opacity: 1;
}

.hero.is-warning .tabs li.is-active a {
  opacity: 1;
  color: #ffe08a !important;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #ffe08a;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
}

@media screen and (width <= 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
  }
}

.hero.is-danger {
  color: #fff;
  background-color: #f14668;
}

.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-danger strong {
  color: inherit;
}

.hero.is-danger .title {
  color: #fff;
}

.hero.is-danger .subtitle {
  color: #ffffffe6;
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: #f14668;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: #ffffffb3;
}

.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger .navbar-link.is-active {
  color: #fff;
  background-color: #ef2e55;
}

.hero.is-danger .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-danger .tabs a:hover {
  opacity: 1;
}

.hero.is-danger .tabs li.is-active a {
  opacity: 1;
  color: #f14668 !important;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}

.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #f14668;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
}

@media screen and (width <= 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
  }
}

.hero.is-small .hero-body {
  padding: 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }

  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (width <= 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (width <= 768px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero-body {
    padding: 3rem;
  }
}

.chart-container {
  position: relative;
}

a {
  text-decoration: underline;
}

body.even-mode .hero {
  animation: 1s infinite blinkingBackground;
}

@keyframes blinkingBackground {
  0% {
    background-color: #10c018;
  }

  25% {
    background-color: #1056c0;
  }

  50% {
    background-color: #b3000c;
  }

  75% {
    background-color: #b3b30c;
  }

  100% {
    background-color: #04a1d5;
  }
}

.present-container {
  position: relative;
}

.present-container img {
  max-width: var(--image-width);
}

.progress-overlay {
  filter: grayscale();
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - var(--progress)), 0% calc(100% - var(--progress)));
  position: absolute;
  inset: 0;
}

/*# sourceMappingURL=index.39d94949.css.map */
