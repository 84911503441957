@charset "utf-8";

:root {
    --image-width: 200px;
    --progress: 2%;
}


// Customization

// You can easily customize Bulma with your own variables.
// Just uncomment the following block to see the result.

// https://www.schemecolor.com/christmas-red-and-green.php

// 1. Import the initial variables
@import "bulma/sass/utilities/initial-variables";

// 2. Set your own initial variables
// Update the blue shade, used for links
// $blue: #06bcef;
$blue: #fff;
// Add pink and its invert
$pink: #b3000c;
$pink-invert: #fff;
// Update the sans-serif font family
$family-sans-serif: "Helvetica", "Arial", sans-serif;

// 3. Set the derived variables
// Use the new pink as the primary color
$primary: $pink;
$primary-invert: $pink-invert;

// 4. Import the rest of Bulma
//@import "bulma/bulma";
@import "bulma/sass/utilities/_all";
@import "bulma/sass/base/_all";
@import "bulma/sass/elements/container";
@import "bulma/sass/elements/title";
@import "bulma/sass/grid/columns";
@import "bulma/sass/layout/hero";

.chart-container {
    position: relative;
  }
  
  a {
      text-decoration: underline;
  }

body.even-mode .hero {
    animation: blinkingBackground 1s infinite;
}
@keyframes blinkingBackground{
    0%   { background-color: #10c018;}
    25%  { background-color: #1056c0;}
    50%	 { background-color: #b3000c;}
    75%  { background-color: #b3b30c;}
    100% { background-color: #04a1d5;}
}

.present-container {
    position: relative;

    img {
        max-width: var(--image-width);
    }
}

.progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: grayscale(1);
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - var(--progress)), 0% calc(100% - var(--progress)));
}